html {

}

body {
    margin: 0;
    font-family: "Raleway", serif;
    background-color: #121212;
}

.main-container {
}

button:focus {
  box-shadow: none;
}

/* --------- */
/*  Preloader */
/* --------- */

/*#scroll {*/
/*  height: 100vh;*/
/*  padding: 0;*/
/*}*/

.scrollable-content {
   margin-top: 60px; /* Adjust this value based on the height of your header */
   margin-bottom: 60px; /* Adjust this value based on the height of your footer */
   overflow-y: auto;
   height: calc(100vh); /* Adjust this value based on the height of your header and footer */
  padding: 0;
 }

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 12px;
}

/* --------- */
/* Home section */
/* --------- */

.home-header {
  padding-top: 30vh;
 /*padding-bottom: 21em;*/
}

.home-header-0 {
    /*padding-top: 6em;*/
    color: #ff9900;
    font-size: 0.85em;
}

.home-header-text-div {
    padding: 0;
}

.home-section {
  /*position: relative;*/
  z-index: -1;
}

.home-content {
  text-align: center;
}

.Typewriter__wrapper {
  font-size: 1.5em;
  /*font-weight: 600;*/
}

.Typewriter__cursor {
  font-size: 2.4em;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em;
    font-weight: 500;
  }
  .Typewriter__cursor {
    display: none;
  }
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  position: fixed;
  bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: normal;
  font-size: 0.7em;
}

.footer-container {
  /*padding: 0;*/
    padding-left: 5em;
}

.footer-copyright {
  text-align: center;
}

.footer-body {
  z-index: 1;
  text-align: center;
}

@media (max-width: 767px) {
  .footer-copyright {
    text-align: center;
  }

  .footer-body {
    text-align: center;
  }
}

.footer-dark-bg {
    background-color: #121212;
    /*color: #fff;*/
}

.footer h3 {
  font-size: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.footer-logo {
    height: 1.5em;
    padding-right: 0.2em;
}

.footer-quote {
    font-size: 1em;
}

/* --------- */
/* Projects */
/* --------- */
.jobs-section {
  position: relative;
  padding-top: 150px;
  padding-bottom: 30px;
}

.btn-primary {
}

.btn-primary:hover {
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative;
  padding-top: 150px;
  padding-bottom: 30px;
}

/* --------- */
/* Investment */
/* --------- */

.contract-section {
  position: relative;
  padding-top: 25rem;
  padding-bottom: 25rem;
}
