

/* --------- */
/* Navbar Section  */
/* --------- */

.navbar {
    position: fixed;
    width: 100%;
    transition: all 0.3s ease-out 0s;
    padding: 0;
    font-size: 1.2rem;
}

.navbar-dark-bg {
    background-color: #121212;
}

.navbar-toggler {
    position: relative;
}

.navbar-toggler span {
    display: block;
    height: 4px;
    width: 27px;
    margin-top: 5px;
    margin-bottom: 5px;
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
    background-color: lightgray;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    transform: rotate(135deg);
    opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    transform: rotate(-135deg);
    opacity: 0.9;
}

@media (max-width: 767px) {
    .navbar {
        padding: 1rem 2rem;
        font-size: 1.4rem;
    }
    .navbar-nav .nav-item a::after {
        display: none;
    }
}
.navbar-brand {
}

.logo {
    height: 1.3em;
    width: 6em;
}

.navbar-nav .nav-link {
    /*padding-right: 1rem;*/
    padding-left: 1rem;
}

.nav-link {
    /*padding: 0.8rem 1rem;*/
    font-size: 0.8em;
}

@media (max-width: 767px) {
    .nav-link {
        /*padding: 0.7rem 1rem;*/
    }
}

.navbar-nav .nav-item {
    position: relative;
    margin-left: 0;
}

.navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
}

.navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
    width: 100%;
}